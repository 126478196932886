<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Home",

  metaInfo: { 
    title: "Home",
    meta: [
    { name: 'description', content: 'AWM Telco specializes in OTT messaging services, empowering businesses with high-quality, scalable messaging solutions for effective customer engagement. Discover our seamless OTT solutions tailored to elevate your communication strategy.' },
    ]
  
  },

  extends: View,

  mixins: [
    LoadSections([
      "hero",
      "theme-features",
      "features",
      // "affiliates",
      // 'social-media',
      // "pro-features",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "home",
    },
  },
};
</script>
